<template>
    <el-tabs v-model="active">
        <el-tab-pane label="消息设置" name="0" class="descriptionsBox">
            <el-descriptions title="用户修改密码提醒" class="m_b1"></el-descriptions>
            <el-form size="small" :model="inputForm" ref="inputForm" label-width="110px" v-loading="loading">
                <el-form-item prop="text" label="消息推送：">
                    <el-checkbox v-model="inputForm.enable" true-label="0" false-label="1">开启</el-checkbox>
                </el-form-item>
                <el-form-item v-show="inputForm.enable == '0'">
                    <el-radio-group class="m_r2" v-model="monthRadio" @input="inputForm.configValue = ''">
                        <el-radio label="one">间隔3个月</el-radio>
                        <el-radio label="two">间隔半年</el-radio>
                        <el-radio label="three">自定义</el-radio>
                    </el-radio-group>
                    <span v-show="monthRadio == 'three'">
                        <el-input class="m_l1 m_r1" v-model.trim="inputForm.configValue" maxlength="2" placeholder="1~12"
                                  clearable style="width: 10%"
                                  @input="inputForm.configValue = inputForm.configValue.replace(/[^\d]/g, '')"></el-input>月
                    </span>
                </el-form-item>
                <el-button class="saveBtn" type="primary" @click="save()" size="small">保存</el-button>
            </el-form>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                active: '0',
                monthRadio: 'one',
                inputForm: {
                    enable: '1',
                    configValue: '',
                },
            }
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            getInfo() {
                this.loading = true
                this.$axios(this.api.user.getUserPasswordChangeReminder).then((res) => {
                    if (res.status) {
                        this.inputForm = res.data
                        if(res.data.configValue == '3') {
                            this.monthRadio = 'one'
                        } else if(res.data.configValue == '6') {
                            this.monthRadio = 'two'
                        } else {
                            this.monthRadio = 'three'
                        }
                    }
                    this.loading = false
                })
            },
            save() {
                let p = JSON.parse(JSON.stringify(this.inputForm))
                if(this.inputForm.enable == '0' && this.monthRadio == 'three' && !p.configValue) {
                    this.$message.error('请输入月数')
                    return;
                }
                if(this.inputForm.enable == '0' && this.monthRadio == 'three' && !/^(?:1[0-2]|[1-9])$/.test(p.configValue)) {
                    this.$message.error('请输入正确的月数')
                    return
                }
                this.loading = true
                if(this.monthRadio == 'one') {
                    p.configValue = '3'
                }
                if(this.monthRadio == 'two') {
                    p.configValue = '6'
                }
                this.$axios(this.api.user.systemConfig, p, 'put').then((res) => {
                    if (res.status) {
                        this.$message.success('保存成功')
                    } else {
                        this.$message.error('保存失败');
                    }
                    this.getInfo()
                    this.loading = false
                })
            },
        }
    }
</script>

<style scoped>
    .descriptionsBox {
        padding: 20px;
        overflow-y: auto;
        height: calc(100vh - 300px);
    }

    .saveBtn {
        position: fixed;
        bottom: 20px;
        left: 55%;
    }
</style>
